import React from 'react';
import PropTypes from 'prop-types';
import { TitleStyled } from './Title.css';
import { RedCircle } from '../Icons/RedCircle';

const Title = React.forwardRef(
  (
    {
      children,
      as = 'span',
      type,
      customStyle,
      customClassName,
      withRedPoint,
      displayNone,
      dangerouslySetInnerHTML,
    },
    ref
  ) => {
    const titleProps = {
      as,
      type,
      ref,
      style: customStyle ? customStyle : null,
      className: customClassName
        ? `${customClassName} ${withRedPoint ? 'Title__withRedPoint' : ''} ${
            displayNone ? 'Title__displayNone' : ''
          }`
        : '',
    };

    return dangerouslySetInnerHTML ? (
      <TitleStyled
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
        {...titleProps}
      />
    ) : (
      <TitleStyled {...titleProps}>
        {withRedPoint ? <RedCircle className='Section__BulletPoint' /> : ''}
        {children}
      </TitleStyled>
    );
  }
);

Title.propTypes = {
  children: PropTypes.any,
  as: PropTypes.string,
  type: PropTypes.oneOf([
    'h1',
    'h2',
    'h2_secondary',
    'h3',
    'h3_secondary',
    'h4',
    'h4-secondary',
  ]),
  customClassName: PropTypes.string,
  customStyle: PropTypes.object,
};

export default Title;
