import styled from "@emotion/styled";
import { theme } from "../../styles";

export const TitleStyled = styled.p`
  font-family: ${theme.fontFamily.secondary};
  font-weight: normal;
  display: flex;
  align-items: center;
  line-height: 1;
  letter-spacing: 0.04em;

  &.Title__displayNone {
    display: none !important;
  }

  ${({ type }) => {
    switch (type) {
      case "h1":
        return `
        font-size: ${theme.fonts.xl_medium};
        `;
      case "h2":
        return `
          font-size: ${theme.fonts.xl_medium};
          `;
      case "h2_secondary":
        return `
          font-family: ${theme.fontFamily.tertiary};
          font-weight: 500;
          font-size: ${theme.fonts.xl_medium};
          `;
      case "h3":
        return `
            font-size: ${theme.fonts.l};
            font-weight: 500;
            line-height: normal;
          `;
      case "h3_secondary":
        return `
            font-size: ${theme.fonts.l};
            font-weight: 500;
            line-height: normal;
            font-family: ${theme.fontFamily.secondary};
            text-transform: uppercase;
          `;
      case "h4":
        return `
            font-size: ${theme.fonts.m}; 
          `;
      case "h4-secondary":
        return `
          font-size: ${theme.scale(theme.fonts.md)}; 
          `;

      default:
        //
        // Default Font Style
        //
        return `
            font-size: ${theme.fonts.s};
        `;
    }
  }};

  @media (min-width: ${theme.breakpoints.md}) {
    ${({ type }) => {
      switch (type) {
        case "h1":
          return `
            font-size: ${theme.scale(theme.fonts.xxxxl)};
          `;
        case "h2":
          return `
            font-size: ${theme.scale(theme.fonts.xxxl)}; 
          `;
        case "h2_secondary":
          return `
            font-size: ${theme.scale(theme.fonts.xxl_large)}; 
          `;
        case "h3":
          return `
            font-size: ${theme.scale(theme.fonts.l)}; 
            font-weight: 500;
            line-height: normal;
          `;
        case "h3_secondary":
          return `
              font-size: ${theme.scale(theme.fonts.xxl_medium)};
              font-weight: 500;
              line-height: normal;
              font-family: ${theme.fontFamily.secondary};
            `;
        case "h4":
          return `
            font-size: ${theme.scale(theme.fonts.md)}; 
          `;

        case "h4-secondary":
          return `
        font-size: ${theme.scale(theme.fonts.xl_medium)}; 
        `;
        default:
          //
          // Default Font Style
          //
          return `
            font-size: ${theme.scale(theme.fonts.m)}; 
          `;
      }
    }};
  }

  .Section__BulletPoint {
    width: 44px;
    margin-right: 35px;

    @media (min-width: ${theme.breakpoints.md}) {
      margin-right: 40px;
    }
  }
`;
