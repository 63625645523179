import React from 'react';

export const RedCircle = ({ className }) => {
  return (
    <svg
      className={className ? className : ''}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 448.92 448.92'
    >
      <circle fill='#f54634' cx='224.46' cy='224.46' r='224.46' />
    </svg>
  );
};
